const experiences = [
  {
    id: 1,
    title: "Software Engineer",
    company: "Taiwan Semiconductor Manufacturing Co., Ltd.",
    date: "July 2021 - Present",
    location: "Hsinchu, Taiwan",
    contents: [
      "Built web crawler, web application, and automated programs to ensure mask data accuracy - reducing 15% mask scrap risk",
      "Re-engineered customer mask data integrated system – reducing future maintenance time by over 30%",
      "Worked with the software architecture team to improve new internal IDE - provide feedback, code samples, and documentation",
    ],
  },
  {
    id: 2,
    title: "Risk Advisory Intern",
    company: "Deloitte",
    date: "Sep 2020 – Mar 2021",
    location: "Taipei, Taiwan",
    contents: [
      "Web Scraped news, technologies, and cybersecurity-related information using Python and Selenium",
      "Made automated tools with Python to manipulate data, such as classifying, analyzing statistically, and consolidating data",
      "Assisted the data analytics team to build a NLP model using BERT framework",
      "Developed internal tools using React Native and Microsoft Azure",
    ],
  },
  {
    id: 3,
    title: "Game Developer Internship",
    company: "Multimedia Company",
    date: "Jul 2020 – Aug 2020",
    location: "Taipei, Taiwan",
    contents: [
      "Lead programmer on a game development team",
      "Created 2 * 2D games and 2 * Augmented Reality applications with Unity in two months",
      "Worked with interns who specialize in art design to produce games from scratch",
      "Gained comprehensive understanding of flow in game development",
    ],
  },
  {
    id: 4,
    title: "Innovating for National Security Internship",
    company: "Qualcomm Institute, UC San Diego",
    date: "Jan 2020 – Mar 2020",
    location: "La Jolla, CA",
    contents: [
      "Developed innovative solutions to national security problems",
      "Worked with U.S. DOD leaders and students from different majors to deliver viable products that match DoD/IC users’ needs in an extremely short time",
      "Interviewed people involved with issues; consolidated and reported their needs to leaders and advisors",
      "Learned to develop sustainable and repeatable model that can be used to launch other potential technology solutions",
    ],
  },
  {
    id: 5,
    title: "Instructional Assistant",
    company: "STEM Center, Foothill College",
    date: "Jun 2019 – Jul 2019",
    location: "Los Altos Hill, CA",
    contents: [
      "Provided one-on-one assistance to students struggling with subjects",
      "Evaluated instructional effectiveness and modified approaches based on feedback and assessments.",
      "Provided assistance to instructors and engaged students in learning activities and positive behaviors to optimize their understanding of lessons.",
      "Used advice from instructors and colleagues to understand students’ instructional needs.",
    ],
  },
  {
    id: 6,
    title: "Clerical Assistant",
    company: "STEM Center, Foothill College",
    date: "Apr 2019 – Jun 2019",
    location: "Los Altos Hill, CA",
    contents: [
      "Assisted students and faculty with access to office devices, and diagnose and troubleshoot mechanical issues when necessary",
      "Worked with club representatives to organize workshops, meetings, and events in the center",
      "Provided clerical office support and front-desk reception, including answering calls and walk-in inquirers from faculties, students, and campus visitors",
    ],
  },
];

export default experiences;
