<template>
    <div class="container m-0 max-w-full p-5 font-sans text-lg border-b-2">
        <nav class="flex items-center justify-between flex-wrap">
            <div class="flex items-center flex-shrink-0 text-[#7a8288] mr-16">
                <span class="tracking-tigh font-bold text-2xl">Haley Lai</span>
            </div>
            <div class="block sm:hidden">
                <button
                    class="flex items-center px-3 py-2 border rounded text-[#7a8288] border-[#7a8288] hover:text-black hover:border-white">
                    <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <title>Menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                    </svg>
                </button>
            </div>
            <div class="w-full block flex-grow sm:flex sm:items-center sm:w-auto">
                <div class="sm:flex-grow">
                    <button @click="$emit('changePage', 'aboutMe')"
                        class="block mt-4 sm:inline-block sm:mt-0 text-[#7a8288] hover:text-black mr-8">
                        About
                    </button>
                    <button @click="$emit('changePage', 'experiences')"
                        class="block mt-4 sm:inline-block sm:mt-0 text-[#7a8288] hover:text-black mr-8">
                        Experiences
                    </button>
                    <button @click="$emit('changePage', 'projects')"
                        class="block mt-4 sm:inline-block sm:mt-0 text-[#7a8288] hover:text-black mr-8">
                        Projects
                    </button>
                    <a href="/HaleyResume.pdf" target="_blank"
                        class="block mt-4 sm:inline-block sm:mt-0 text-[#7a8288] hover:text-black">
                        Resume
                    </a>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'NavBar',
}
</script>