<template>
    <h1 class="text-3xl font-bold mb-3">About</h1>
    <p class="leading-relaxed">My name is Haley Lai, and I am a software engineer with experience in building
        automated solutions for streamlining business processes (Vue.js, Java, SQL, web crawler), researching
        machine learning/deep learning solutions for challenging problems (Python, Tensorflow), and identifying
        opportunities to optimize existing systems. I am eager to learn new skills and technologies to provide
        creative ideas when facing different software development problems. Recently, I like to solve coding
        problems on Leetcode, which helping me to write neat, elegant, and efficient code, and making me feel
        accomplished when seeing <b class="text-[#2BAA4B]">ACCEPT</b> in one pass. 😊
    </p>
    <h1 class="text-3xl font-bold mt-10 mb-3">Technologies</h1>
    <ul class="list-disc leading-loose list-inside">
        <li><b>Programming Lanugage: </b>Python, Java, C++</li>
        <li><b>Web Dev: </b>JavaScript, Vue.js, JSP, HTML, CSS, WebLogic, Tailwind</li>
        <li><b>Database: </b>Oracle PL/SQL, MySql</li>
        <li><b>Data Analytics: </b>TensorFlow, NumPy, Pandas, Scikit-Learn, Jupyter Notebook</li>
    </ul>
</template>

<script>

export default {
    name: 'AboutMe',
}
</script>

<style>

</style>
