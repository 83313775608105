<template>
    <div class="flex flex-wrap font-sans text-lg text-center justify-center">
        <div class="border-2 h-32 w-32 rounded-full mb-1"></div>
        <h1 class="mt-3 font-bold text-xl text-[#313436]">Haley Lai</h1>
        <span class="text-lg my-3">Software Engineer</span>
        <ul class="space-y-2 text-md grid justify-items-start">
            <li>
                <a href="mailto:haley1617@gmail.com" class="hover:underline"><i
                        class="fa-regular fa-envelope mr-2 text-[#E22B29]"></i>Email</a>
            </li>
            <li>
                <a href="https://www.linkedin.com/in/haley-lai-040974197/" class="hover:underline" target="_blank"><i
                        class="fa-brands fa-linkedin text-[#0E4EB5] mr-2"></i>LinkedIn</a>
            </li>
            <li>
                <a href="https://github.com/Haley-1617" class="hover:underline" target="_blank"><i
                        class="fa-brands fa-github mr-2"></i>Github</a>
            </li>
            <li>
                <a href="https://leetcode.com/haley5157/" class="hover:underline" target="_blank">
                    <div class="flex items-center">
                        <img class="mr-2" src="../assets/leetcode.svg" />
                        <span>LeetCode</span>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "SideBar",
};
</script>
