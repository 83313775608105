<template>

  <NavBar @changePage="loadContent" />
  <div class="grid grid-cols-[200px_minmax(10px,_1fr)] mt-10">
    <SideBar class="sticky top-10 self-start" />
    <div class="container pl-10 pr-28 max-w-3xl">
      <template v-if="curPage === 'aboutMe'">
        <AboutMe />
      </template>
      <template v-if="curPage === 'experiences'">
        <h1 class="text-3xl font-bold">Experiences</h1>
        <div class="font-lato">
          <template v-for="item in jobItems" :key="item.id">
            <JobCard :job="item" />
          </template>
        </div>
      </template>
      <template v-if="curPage === 'projects'">
        <h1 class="text-3xl font-bold">Projects</h1>
        <div class="font-lato mt-3">
          Working...
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import SideBar from "./components/SideBar.vue"
import AboutMe from "./components/AboutMe.vue"
import experiences from "./data/experiences"
import JobCard from "./components/JobCard.vue"

export default {
  name: 'App',
  components: {
    NavBar,
    SideBar,
    AboutMe,
    JobCard
  },
  data() {
    return { jobItems: experiences, curPage: "aboutMe" }
  },
  methods: {
    loadContent(page) {
      if (this.curPage == page) return
      this.curPage = page
    }
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
