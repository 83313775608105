<template>
    <h2 class="text-lg mt-3 font-bold leading-relaxed">{{ job.title }}</h2>
    <h3 class="text-lg font-bold text-[#093EB1] leading-relaxed">{{ job.company }}</h3>
    <div class="columns-2 gap-8 text-md leading-loose">
        <h2><i class="fa-regular fa-calendar mr-2"></i>{{ job.date }}</h2>
        <h2><i class="fa-solid fa-location-pin mr-2"></i>{{ job.location }}</h2>
    </div>
    <div class="border-b-2 pb-3">
        <ul class="text-base list-disc leading-relaxed list-inside " v-for="content in job.contents" :key="content">
            <li>{{ content }}</li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        job: {
            type: Object
        }
    },
    name: 'JobCard',
}
</script>